import { FC, ReactNode, useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import clsx from 'clsx'
import dynamic from 'next/dynamic'

const PortalWrap = dynamic(() => import('../portal-wrap/portal-wrap'), { ssr: false })
import ScrollArea from '../scroll-area/scroll-area'
import useNavbarHeight from 'store/navbar/useNavbarHeight'
import usePopupScrollPosition from 'hooks/usePopupScrollPosition'

import { STATIC_URL } from 'constants/data'
import { Events } from 'constants/events'

import styles from './fullscreen-popup-wrapper.module.scss'

const iconClose = `${STATIC_URL}/filters/icon_close.svg`

interface IPopupWrapper {
  children?: ReactNode
  animationIn: boolean
  modificator?: string
  handleClose?: () => void
}

const FullscreenPopupWrapper: FC<IPopupWrapper> = ({
  animationIn,
  handleClose,
  modificator = '',
  children,
}: IPopupWrapper) => {
  const { navbarHeight } = useNavbarHeight()
  const [height, setHeight] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      const sizes = [window?.visualViewport?.height, window?.innerHeight].filter((item) => item)
      const windowHeight = Math.max(...(sizes as number[]))
      setHeight(windowHeight - navbarHeight)
    }

    if (animationIn) {
      handleResize()

      window.addEventListener(Events.RESIZE, handleResize)
      return () => window.removeEventListener(Events.RESIZE, handleResize)
    }
  }, [navbarHeight, animationIn])

  usePopupScrollPosition(animationIn)

  return (
    <PortalWrap>
      <CSSTransition
        in={Boolean(animationIn)}
        timeout={400}
        unmountOnExit
        classNames={{
          enter: styles.topEnter,
          enterActive: styles.topEnterActive,
          exit: styles.topExit,
          exitActive: styles.topExitActive,
        }}
      >
        <div
          className={clsx(styles.popupWrapper, {
            [modificator]: modificator,
          })}
          style={{ top: navbarHeight, maxHeight: height }}
        >
          <div className={styles.innerContainer}>
            <ScrollArea modificator={styles.scrollArea}>
              <img
                src={iconClose}
                className={styles.btnClose}
                alt="icon close"
                decoding="async"
                loading="lazy"
                onClick={handleClose}
              />
              <div className={styles.centeredContainer}>{children}</div>
            </ScrollArea>
          </div>
        </div>
      </CSSTransition>
    </PortalWrap>
  )
}

export default FullscreenPopupWrapper
