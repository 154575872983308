import { FC, useCallback, useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

import A from 'utils/metrics/analytic'
import GTAG from 'utils/metrics/gtagMethods'
const LoginPopup = dynamic(() => import('../login-form/login-form'))
const RecoverPassword = dynamic(() => import('../recover-password/recover-password'))
const RecoverSuccess = dynamic(() => import('../recover-success/recover-success'))
const RegistrationPopup = dynamic(() => import('../registration/registration'))
const RegistrationOlympPopup = dynamic(() => import('../registration/registrationOlymp'))
const RegistrationWithStepsPopup = dynamic(() => import('../registration/registrationWithSteps'))
const RegistrationGeriatricsSchool = dynamic(
  () => import('../registration/registrationGeriatricsSchool')
)
const AnketaPopup = dynamic(() => import('../anketa/anketa'))
import FullscreenPopupWrapper from 'containers/fullscreen-popup-wrapper/fullscreen-popup-wrapper'
import Auth from 'store/auth'
import useActivePopupStatus from 'store/common/useActivePopupStatus'
import useTitle from 'store/common/useTitle'

import { YaMetricHit } from 'utils/metrics/analyticMethods'
import { cutHashFromURL } from 'utils/getURLData'
import { popupName } from 'constants/auth/auth-data'
import { AdditionPageTitle, LoginHashPopup, RegHashPopup, TransitionEvent } from './data'
import { SessionPopupsKeys } from 'constants/storage'

const HashPopup: FC = () => {
  const { isAuthorized, isNotAuthorized, isFilledUser } = Auth.useAuth()
  const { hashPopupStatus, setHashPopupStatus } = useActivePopupStatus()
  const { setPageTitle } = useTitle()
  const router = useRouter()
  const [component, setComponent] = useState<React.ReactElement | null>(null)

  const path = cutHashFromURL(router.asPath)

  const handleClose = useCallback(() => {
    hashPopupStatus && setHashPopupStatus()
    router.replace(path, undefined, { shallow: true })
  }, [])

  const afterLoginHandlerGeriatricsSchool = () => {
    sessionStorage.setItem(SessionPopupsKeys.GERIATRICS_SCHOOL_GER_POPUP, 'true')
  }

  useEffect(() => {
    let content = null

    if (LoginHashPopup.includes(hashPopupStatus) && isNotAuthorized) content = <LoginPopup />
    else if (hashPopupStatus === popupName.loginGeriatricsSchool)
      content = <LoginPopup onSubmitEnd={afterLoginHandlerGeriatricsSchool} />
    else if (hashPopupStatus === popupName.recoverPassword) content = <RecoverPassword />
    else if (hashPopupStatus === popupName.recoverSuccess) content = <RecoverSuccess />
    else if (RegHashPopup.includes(hashPopupStatus) && isNotAuthorized)
      content = <RegistrationPopup />
    else if (hashPopupStatus === popupName.registrationWithSteps)
      content = <RegistrationWithStepsPopup handleClose={handleClose} />
    else if (hashPopupStatus === popupName.anketa && isAuthorized && !isFilledUser)
      content = <AnketaPopup handleClose={handleClose} />
    else if (hashPopupStatus === popupName.registratiomStudentsOlymp)
      content = <RegistrationOlympPopup handleClose={handleClose} />
    else if (hashPopupStatus === popupName.geriatricsSchool)
      content = <RegistrationGeriatricsSchool />

    const isContent = Boolean(content)
    if (isContent) {
      A.setEvent(TransitionEvent[hashPopupStatus as keyof typeof TransitionEvent])
    }
    const nextPopupStatus =
      (isContent && AdditionPageTitle[hashPopupStatus as keyof typeof AdditionPageTitle]) || ''

    setPageTitle(nextPopupStatus)
    GTAG.pageview(window.location.href)
    YaMetricHit(window.location.href)

    setComponent(content)
  }, [hashPopupStatus, isNotAuthorized, isAuthorized, handleClose, isFilledUser])

  const isComponentActive = Boolean(hashPopupStatus) && Boolean(component)
  return (
    <FullscreenPopupWrapper animationIn={isComponentActive} handleClose={handleClose}>
      {component}
    </FullscreenPopupWrapper>
  )
}

export default HashPopup
